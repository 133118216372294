











import { Component, Vue } from 'vue-property-decorator'

import SubscriptionAttachmentForm from '@/partials/forms/Events/Subscriptions/AttachmentsForm.vue'
import CenteredThin from '@/layouts/Panels/CenteredThin30-50.vue'

@Component({
  components: {
    CenteredThin,
    SubscriptionAttachmentForm
  }
})
export default class EventSubscriptionAttachmentsForm extends Vue {

}
