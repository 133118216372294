



















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'

@Component({
  directives: { mask },
  components: {
    FileUploader,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventSubscriptionAttachmentsForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) subscriptionId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'

  done = true
  form: Record<string, any> = {
    title: '',
    file: '',
    eventSubscriptionId: '',
    notes: '',
    confirm: '',
    errors: {
      title: '',
      file: '',
      eventSubscriptionId: '',
      notes: '',
      confirm: ''
    }
  }

  created () {
    if (process.env.NODE_ENV === 'development') {
      Object.assign(this.form, {
        title: 'Titulo TESTE',
        notes: 'My notes',
        confirm: ''
      })
    }

    this.form.eventSubscriptionId = this.subscriptionId
  }

  submit () {
    this.done = false
    axios.post(`event/${this.eventId}}/subscription/${this.subscriptionId}/attachment`, snakeCaseKeys(this.form, { deep: true }))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const flashMessage = {
      message: 'O Documento foi cadastrado com sucesso.',
      kind: 'success'
    }

    this.$router.push({ name: 'EventShow', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      file: '',
      eventActivityId: '',
      notes: '',
      confirm: ''
    }
  }
}
