



















import { Component, Vue, Prop, ModelSync } from 'vue-property-decorator'

@Component
export default class FileUploader extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: Array }) acceptedFormats!: Array<string>
  uploader: UploaderArray = []
  filesizeMB = 0

  onChange (event: Array<any>) {
    if (event.length === 0) {
      this.inputField = ''
    }
  }

  async onUpload ($e: any) {
    if ($e.target.files.length === 0) return
    const filename = $e.target.files[0].name
    const fileExt = filename.split(/(?=\.)/g).slice(-1).pop().toLowerCase()
    const base64file = await this.toBase64($e.target.files[0]) as string

    this.filesizeMB = new Blob([base64file]).size / 10 ** 6
    const isAcceptedFormat = this.accept.includes(fileExt) || Boolean($e.target.files[0].type.match(this.acceptedFormats[0]))

    if (isAcceptedFormat && this.filesizeMB < 50) {
      this.uploader[0].state = 'complete'
      this.inputField = base64file
    } else {
      this.uploader[0].state = ''
      this.uploader[0].invalidMessage = isAcceptedFormat ? 'Arquivo excede 50 MB' : 'Formato não suportado'
      this.uploader[0].invalidMessageTitle = 'Erro'
    }
  }

  toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  });

  get accept () {
    return this.acceptedFormats ?? ['.epub', '.doc', '.pdf', '.rtf', '.xls', '.xlsx', '.docx', '.ppt', '.pptx', '.gif', '.jpg', '.png', '.txt', '.csv']
  }
}
